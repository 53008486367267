const MsgError = (props) => {

    return (

        <>
        <h1>Erro na aplicação: {props.valorMsgError} </h1>
        </>

    )


}

export default MsgError
import './App.css';
import { RegisterUser } from '../index'
import { Post } from '../Resources';


function App() {
  return (
    <div className="App">
      <RegisterUser />


    </div>
  );
}

export default App;

import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";

const Post = (props) => {

    const baseURL = 'http://api.tucanocloud.com.br:8080';
    const [responseServer, setResponseServer] = useState()

    console.log(props.valorValueForm.name)
    console.log(props.valorValueForm.email)

    useEffect(() => {

        axios.post(`${baseURL}/cadastrar`, {
            name: props.valorValueForm.name,
            email: props.valorValueForm.email
        })
            .then((response) => {
                console.log(response)
                setResponseServer(response)
            }).catch((response) => {
                console.log('erro', response)
            })


    }, [])



    return (
        <>

        </>

    )

}

export default Post